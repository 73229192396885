#_stickybox_ {
    height: 85%;
    position: fixed;
    right: 20px;
    bottom: 70px;
    z-index: 9999 !important;
    border: 1px solid #eaeaea;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, .10) 1px 1px 15px 0;
    -webkit-animation: fadein .85s;
    -moz-animation: fadein .85s;
    -ms-animation: fadein .85s;
    -o-animation: fadein .85s;
    animation: fadein .85s;
    background: #f7f7f7 !important;
    overflow: scroll;
    overflow-x: hidden;
    overflow-y: scroll;
    padding-left: 0px;
    padding-right: 0px;
    display: none
}

#_stickybox_.visible {
    display: block
}

#my-notification-button {
    color: white !important;
}

#a-c-ask {
    display: none;
}

#a-c-icon {
    height: 60px;
    width: 60px;
    position: fixed;
    right: 20px;
    bottom: 15px;
    z-index: 10000 !important;
    border: 1px solid #eaeaea;
    border-radius: 50%;
    background: #F98F54;
    background: -webkit-linear-gradient(top left, #F98F54, #FF5C5A);
    background: -moz-linear-gradient(top left, #F98F54, #FF5C5A);
    background: linear-gradient(top left, #F98F54, #FF5C5A);
    box-shadow: rgba(0, 0, 0, .10) 1px 1px 15px 0;
    -webkit-animation: fadein .85s;
    -moz-animation: fadein .85s;
    -ms-animation: fadein .85s;
    -o-animation: fadein .85s;
    animation: fadein .85s;
    padding-left: 0px;
    padding-right: 0px;
    cursor: pointer;
}

#a-c-icon.toggle {
    background: #31959C !important;
    background: -webkit-linear-gradient(bottom right, #31959C, #173284) !important;
    background: -moz-linear-gradient(bottom right, #31959C, #173284) !important;
    background: linear-gradient(bottom right, #31959C, #173284) !important;
    border-color: #31959C !important;
}

#a-c-icon.toggle .bell {
    -webkit-animation: inherit;
    -moz-animation: inherit;
    animation: inherit;
}

.a-c-l {
    margin: 12px;
}

.a-c-img {
    width: 100%;
}

.a-c-h {
    position: fixed;
    width: inherit;
    height: 100px;
    z-index: 1000;
    background: #31959C !important;
    background: -webkit-linear-gradient(bottom right, #31959C, #173284) !important;
    background: -moz-linear-gradient(bottom right, #31959C, #173284) !important;
    background: linear-gradient(bottom right, #31959C, #173284) !important;
    border-color: #31959C !important;
}

.a-c-t {
    margin-top: 30px;
    text-align: center;
}

.a-c-n-t {
    font-size: 20px;
    font-weight: bold;
}

li.a-c-i {
    margin-bottom: 13px;
    padding: 15px;
    background: #fff;
    border-radius: 2px;
    position: relative;
    max-width: 800px;
    margin: 20px auto;
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .24);
    box-shadow: 0 1px 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .24);
    -webkit-transition: all .3s cubic-bezier(.25, .8, .25, 1);
    -o-transition: all .3s cubic-bezier(.25, .8, .25, 1);
    transition: all .3s cubic-bezier(.25, .8, .25, 1);
}

.a-c-f {
    margin: -15px!important;
    background: rgba(156, 156, 156, .1);
    padding: 10px;
    margin-top: 35px!important;
    border-top: 1px solid rgba(195, 193, 193, .45);
    position: relative;
    padding-top: 22px;
    height: auto;
    min-height: 65px;
    -webkit-transition: all .5s ease;
    -o-transition: all .5s ease;
    transition: all .5s ease;
    -webkit-transition-delay: .2s ease;
    -o-transition-delay: .2s ease;
    transition-delay: .2s ease;
}

.a-c-p {
    background: #fff;
    padding-top: 10px;
    margin-top: 25px!important;
    min-height: 55px;
}

.a-c-f-i {
    border: 1px solid #eaeaea;
    border-radius: 2px;
    font-size: 13px;
    overflow: hidden;
    padding: 9px 7px 7px;
    resize: none;
    height: 32px;
    width: 100%;
}

.a-c-b {
    background: #ff5a80 !important;
    font-size: 10px;
    margin: 3px;
}

.a-c-d {
    color: #8da2b5;
    display: inline-block;
    font-size: 14px
}

.a-c-i-action {
    color: white;
    height: 60px;
    text-align: center;
    display: inline-block;
    font-size: 30px;
    padding: 10px 15px
}

.bell {
    -webkit-animation: ring 4s .7s ease-in-out infinite;
    -webkit-transform-origin: 50% 4px;
    -moz-animation: ring 4s .7s ease-in-out infinite;
    -moz-transform-origin: 50% 4px;
    animation: ring 4s .7s ease-in-out infinite;
    transform-origin: 50% 4px;
}

#a-c-close {
    margin-right: 15px;
    cursor: pointer;
}

#a-c-share-icon {
    cursor: pointer
}

.a-c-logo {
    float: left;
    height: 50px;
    margin-right: 10px
}

.a-c-nt {
    font-weight: normal;
    color: #8da2b5;
    font-size: 14px
}

.a-c-panel-body {
    height: 100px
}

@-webkit-keyframes ring {
    0% {
        -webkit-transform: rotateZ(0);
    }
    1% {
        -webkit-transform: rotateZ(30deg);
    }
    3% {
        -webkit-transform: rotateZ(-28deg);
    }
    5% {
        -webkit-transform: rotateZ(34deg);
    }
    7% {
        -webkit-transform: rotateZ(-32deg);
    }
    9% {
        -webkit-transform: rotateZ(30deg);
    }
    11% {
        -webkit-transform: rotateZ(-28deg);
    }
    13% {
        -webkit-transform: rotateZ(26deg);
    }
    15% {
        -webkit-transform: rotateZ(-24deg);
    }
    17% {
        -webkit-transform: rotateZ(22deg);
    }
    19% {
        -webkit-transform: rotateZ(-20deg);
    }
    21% {
        -webkit-transform: rotateZ(18deg);
    }
    23% {
        -webkit-transform: rotateZ(-16deg);
    }
    25% {
        -webkit-transform: rotateZ(14deg);
    }
    27% {
        -webkit-transform: rotateZ(-12deg);
    }
    29% {
        -webkit-transform: rotateZ(10deg);
    }
    31% {
        -webkit-transform: rotateZ(-8deg);
    }
    33% {
        -webkit-transform: rotateZ(6deg);
    }
    35% {
        -webkit-transform: rotateZ(-4deg);
    }
    37% {
        -webkit-transform: rotateZ(2deg);
    }
    39% {
        -webkit-transform: rotateZ(-1deg);
    }
    41% {
        -webkit-transform: rotateZ(1deg);
    }
    43% {
        -webkit-transform: rotateZ(0);
    }
    100% {
        -webkit-transform: rotateZ(0);
    }
}

@-moz-keyframes ring {
    0% {
        -moz-transform: rotate(0);
    }
    1% {
        -moz-transform: rotate(30deg);
    }
    3% {
        -moz-transform: rotate(-28deg);
    }
    5% {
        -moz-transform: rotate(34deg);
    }
    7% {
        -moz-transform: rotate(-32deg);
    }
    9% {
        -moz-transform: rotate(30deg);
    }
    11% {
        -moz-transform: rotate(-28deg);
    }
    13% {
        -moz-transform: rotate(26deg);
    }
    15% {
        -moz-transform: rotate(-24deg);
    }
    17% {
        -moz-transform: rotate(22deg);
    }
    19% {
        -moz-transform: rotate(-20deg);
    }
    21% {
        -moz-transform: rotate(18deg);
    }
    23% {
        -moz-transform: rotate(-16deg);
    }
    25% {
        -moz-transform: rotate(14deg);
    }
    27% {
        -moz-transform: rotate(-12deg);
    }
    29% {
        -moz-transform: rotate(10deg);
    }
    31% {
        -moz-transform: rotate(-8deg);
    }
    33% {
        -moz-transform: rotate(6deg);
    }
    35% {
        -moz-transform: rotate(-4deg);
    }
    37% {
        -moz-transform: rotate(2deg);
    }
    39% {
        -moz-transform: rotate(-1deg);
    }
    41% {
        -moz-transform: rotate(1deg);
    }
    43% {
        -moz-transform: rotate(0);
    }
    100% {
        -moz-transform: rotate(0);
    }
}

@keyframes ring {
    0% {
        transform: rotate(0);
    }
    1% {
        transform: rotate(30deg);
    }
    3% {
        transform: rotate(-28deg);
    }
    5% {
        transform: rotate(34deg);
    }
    7% {
        transform: rotate(-32deg);
    }
    9% {
        transform: rotate(30deg);
    }
    11% {
        transform: rotate(-28deg);
    }
    13% {
        transform: rotate(26deg);
    }
    15% {
        transform: rotate(-24deg);
    }
    17% {
        transform: rotate(22deg);
    }
    19% {
        transform: rotate(-20deg);
    }
    21% {
        transform: rotate(18deg);
    }
    23% {
        transform: rotate(-16deg);
    }
    25% {
        transform: rotate(14deg);
    }
    27% {
        transform: rotate(-12deg);
    }
    29% {
        transform: rotate(10deg);
    }
    31% {
        transform: rotate(-8deg);
    }
    33% {
        transform: rotate(6deg);
    }
    35% {
        transform: rotate(-4deg);
    }
    37% {
        transform: rotate(2deg);
    }
    39% {
        transform: rotate(-1deg);
    }
    41% {
        transform: rotate(1deg);
    }
    43% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(0);
    }
}

#rap-overlay {
    position: fixed;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparent;
    z-index: 2;
    cursor: pointer;
}

.rap-manager,
.rap-target-container {
    display: inline-block;
    position: relative;
}

.rap-left {
    left: -20px !important;
}

.rap-bottom {
    top: 15px !important;
}

.rap-top {
    top: -15px !important;
}

.rap-right {
    left: 20px !important;
}

.ar {
    transform: rotate(-180deg);
}

.at {
    transform: rotate(90deg);
}

.ab {
    transform: rotate(-90deg);
}

.SocialMediaShareButton {
    width: 32px;
    display: inline-block;
    margin: 5px;
}

.share-menu {
    display: block;
    background: white;
    min-width: 220px;
    border: 1px solid #efefef;
    padding: 2px;
    border-radius: 6px;
    box-shadow: 0px 0px 1px #777;
    z-index: 1;
    position: relative;
}

.a-c-n-t a,
.a-c-n-t a:link,
.a-c-n-t a:visited {
    color: black;
    text-decoration: none;
}

.a-c-n-t a:hover,
.a-c-n-t a:active {
    color: #173184;
    text-decoration: none;
}